<template>
  <!-- Vista general de procesos -->
  <v-container fluid>
    <p class="text-h4 secondary--text mb-0">Procesos de compra</p>
    <p class="text-h6 secondary--text m-0">Ejercicio {{ anioFiscal }}</p>

    <v-row
      class="mt-4"
      v-if="!this.haveRoles(['ROLE_UFI', 'ROLE_UFI_TECNICO'])"
    >
      <v-col cols="12" sm="4">
        <v-btn
          block
          color="secondary"
          @click="modalAddProceso = true"
          v-if="
            haveRoles([
              'ROLE_UACI',
              'ROLE_UACI_TECNICO',
              'ROLE_TECNICO_DIRECCION_COMPRAS',
            ])
          "
        >
          Agregar proceso
        </v-btn>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="12" sm="12" md="4">
        <v-text-field
          v-model="filters.nombre"
          label="No. de proceso"
          outlined
          hide-details
        />
      </v-col>

      <v-col cols="12" sm="12" md="4">
        <v-select
          label="Modalidad de compra"
          :items="ctlModalidades"
          item-value="id"
          item-text="nombre"
          outlined
          hide-details
          v-model="filters.id_forma_contratacion"
        />
      </v-col>
      <v-col
        v-if="haveRoles(['ROLE_DIRECCION_COMPRAS']) && false"
        cols="12"
        sm="12"
        md="4"
      >
        <v-select
          label="Interés del proceso"
          :items="ctlIntereses"
          item-value="id"
          item-text="nombre"
          outlined
          hide-details
          v-model="filters.id_interes_presidente"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-select
          label="Estado"
          :items="ctlEstados"
          item-value="id"
          item-text="nombre"
          outlined
          hide-details
          v-model="filters.id_seguimiento_proceso"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-menu
          ref="menuDataRange"
          v-model="menuDataRange"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              v-model="dateRangeText"
              label="Desde - Hasta"
              prepend-inner-icon="mdi-calendar"
              readonly
              hide-details
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            title="es"
            :max="fechaMaximaFiltro"
            v-model="fechas"
            range
            @change="setFiltrosFechas"
            locale="es-Es"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="12" md="4" v-if="haveRoles(['ROLE_DIRECCION_COMPRAS','ROLE_FILTRO_INSTITUCION_PROCESOS'])">
        <v-autocomplete
          label="Institución"
          :items="ctlInstituciones"
          item-value="id"
          item-text="nombre"
          outlined
          hide-details
          clearable
          v-model="filters.id_institucion"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <div class="d-flex align-center justify-space-around">
          <v-col>
            <v-btn dark block color="secondary" @click="obtainListProcesos()"
              >Buscar</v-btn
            >
          </v-col>
          <v-col>
            <v-btn outlined block color="secondary" @click="clearFiltros()"
              >Limpiar</v-btn
            >
          </v-col>
        </div>
      </v-col>
    </v-row>
    <p class="font-weight-bold secondary--text mt-8">Listado de procesos</p>
    <div
      v-if="haveRoles(['ROLE_DIRECCION_COMPRAS'])"
      class="d-flex align-center mb-2"
    >
      <div class="d-flex align-center mr-6">
        <v-icon size="35" class="customProcessColor">mdi-circle</v-icon>
        <p class="mb-0 ml-2">Procesos reservados</p>
      </div>
      <!-- <div class="d-flex align-center mr-2">
        <v-avatar size="35" color="pendiente">
          <v-icon size="20">mdi-alert</v-icon>
        </v-avatar>
        <p class="mb-0 ml-2">Procesos con prevención</p>
      </div> -->
    </div>
    <data-table-component
      v-models:select="perPage"
      v-models:pagina="page"
      :headers="headers"
      :items="items"
      @paginar="paginate"
      :total_registros="totalRows"
    >
      <template #[`item.id_tipo_proceso`]="{ item }">
        <v-icon
          style="font-size: 25px !important"
          v-if="item.id_tipo_proceso == 2"
          class="mr-2 customProcessColor"
          >mdi-circle</v-icon
        >
        <!-- <v-avatar v-if="item.id_tipo_proceso == 3" size="25" color="pendiente">
          <v-icon style="font-size: 20px !important">mdi-alert</v-icon>
        </v-avatar> -->
      </template>
      <template #[`item.codigo_proceso`]="{ item }">
        <span>
          {{ item.codigo_proceso }}
        </span>
      </template>
      <template #[`item.fecha_inicio_proceso`]="{ item }">
        <span>
          {{ moment(item.fecha_inicio_proceso).format("MMMM / YYYY") }}
        </span>
      </template>
      <template #[`item.institucion`]="{ item }">
        <span>
          {{ item.EmpleadoUnidad?.Unidad?.Institucion?.nombre }}
        </span>
      </template>
      <template #[`item.fecha_contratacion`]="{ item }">
        <span>
          {{ moment(item.fecha_contratacion).format("DD/MM/YYYY") }}
        </span>
      </template>
      <template v-slot:[`item.suma_mercancia`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.suma_mercancia)
        }}
      </template>
      <template v-slot:[`item.estado`]="{ item }">
        <v-chip label dark :color="item.seguimiento_proceso?.color">
          {{ item.seguimiento_proceso?.nombre || "" }}
        </v-chip>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip
          v-if="
            item.seguimiento_proceso?.id !== 1 ||
            haveRoles([
              'ROLE_DIRECCION_COMPRAS',
              'ROLE_TECNICO_DIRECCION_COMPRAS',
            ]) ||
            haveRole('ROLE_TITULAR_ESTABLECIMIENTO') &
              !haveRole('ROLE_DIRECCION_COMPRAS')
          "
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="
                $router.push({
                  name: 'pac-procesos-etapas',
                  params: { idProceso: item.id },
                })
              "
              v-bind="attrs"
              v-on="on"
              color="secondary"
            >
              mdi-eye
            </v-icon>
          </template>
          <span> Ver seguimiento</span>
        </v-tooltip>

        <v-tooltip
          v-if="
            haveRoles([
              'ROLE_UACI',
              'ROLE_DIRECCION_COMPRAS',
              'ROLE_TECNICO_DIRECCION_COMPRAS',
            ]) || item.Empleado?.id_usuario == userInfo?.user?.id
          "
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="
                $router.push({
                  name: 'pac-procesos-configuracion',
                  params: { idProceso: item.id },
                })
              "
              v-bind="attrs"
              v-on="on"
              color="secondary"
            >
              mdi-cog
            </v-icon>
          </template>
          <span> Configuración</span>
        </v-tooltip>

        <!-- Boton para acceder a configuracion DINAC-->
        <v-tooltip
          v-if="
            haveRoles(['ROLE_DIRECCION_COMPRAS']) &&
            !haveRole('ROLE_TECNICO_DIRECCION_COMPRAS')
          "
          top
        >
          <!--TODO:Redirigir a vista que esta haciendo Amimir-->
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="
                $router.push({
                  name: 'pac-procesos-detalle',
                  params: { idProceso: item.id },
                })
              "
              v-bind="attrs"
              v-on="on"
              color="secondary"
            >
              mdi-tools
            </v-icon>
          </template>
          <span> Configuración</span>
        </v-tooltip>
        <!-- Boton para acceder a configuracion DINAC-->

        <v-tooltip
          v-if="
            item.seguimiento_proceso?.id == 1 &&
            item.id_usuario == userInfo.user?.id
          "
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="(deleteDialog = true), (proceso_seleccionado = item.id)"
              v-bind="attrs"
              v-on="on"
              color="secondary"
            >
              mdi-delete
            </v-icon>
          </template>
          <span> Eliminar</span>
        </v-tooltip>

        <v-tooltip
          v-if="
            haveRoles(['ROLE_UFI', 'ROLE_UFI_TECNICO']) &&
            item.seguimiento_proceso.id == 9
          "
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="secondary"
              @click="asignarCdp(item.id)"
            >
              mdi-certificate
            </v-icon>
          </template>
          <span> Asignar CDP</span>
        </v-tooltip>
      </template>
    </data-table-component>

    <AgregarProcesoComponent
      :show="modalAddProceso"
      :close="closeModalAddProceso"
      :action="obtainListProcesos"
      :modalidades="ctlModalidades"
    />

    <dialogoConfirmacion
      :show="deleteDialog"
      title="¿Desea eliminar este proceso de compra?"
      message="De confirmarse la siguiente acción los cambios no serán reversibles"
      btnConfirmar="Aceptar"
      @close="deleteDialog = false"
      @confirm="deleteProceso()"
    />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import DataTableComponent from "@/components/DataTableComponent.vue";
import AgregarProcesoComponent from "./components/AgregarProcesoComponent.vue";
import dialogoConfirmacion from "../../components/ConfirmationDialogComponent.vue";
import moment from "moment";

export default {
  name: "PACListView",
  components: {
    DataTableComponent,
    AgregarProcesoComponent,
    dialogoConfirmacion,
  },
  data: () => ({
    headers: [
      {
        text: "",
        value: "id_tipo_proceso",
        sortable: false,
        align: "center",
      },
      {
        text: "Código",
        value: "codigo_proceso",
      },
      {
        text: "Código interno",
        value: "codigo_interno",
      },
      {
        text: "Proceso",
        value: "nombre_proceso",
      },
      {
        text: "Modalidad de compra",
        value: "forma_contratacion.nombre",
        align: "right",
      },
      {
        text: "Institución",
        value: "institucion",
        align: "right",
      },
      {
        text: "Mes programado",
        value: "fecha_inicio_proceso",
        align: "right",
      },
      {
        text: "Fecha solicitado",
        value: "fecha_contratacion",
        align: "right",
      },
      {
        text: "Monto programado($)",
        value: "suma_mercancia",
        align: "center",
      },
      {
        text: "Estado",
        value: "estado",
        align: "center",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
    items: [],
    filters: {
      id_seguimiento_proceso: null,
      id_forma_contratacion: null,
      nombre: null,
      fecha_desde: null,
      fecha_hasta: null,
      id_interes_presidente: null,
      id_institucion: null,
    },
    totalRows: 0,
    page: 1,
    perPage: 10,
    ctlModalidades: [],
    ctlEstados: [
      {
        id: 1,
        nombre: "Configuración proceso",
      },
      {
        id: 2,
        nombre: "Proceso publicado",
      },
      {
        id: 6,
        nombre: "Seguimiento",
      },
      {
        id: 9,
        nombre: "Solicitud de CDP",
      },
      {
        id: 10,
        nombre: "CDP asignado",
      },
    ],
    ctlIntereses: [
      { id: 3, nombre: "Todos" },
      { id: 1, nombre: "Procesos de indole institucional" },
      { id: 2, nombre: "Procesos reservados" },
    ],
    ctlInstituciones: [],
    timer: null,
    modalAddProceso: false,
    deleteDialog: false,
    proceso_seleccionado: null,
    menuDataRange: false,
    fechas: ["", ""],
  }),

  computed: {
    ...mapState(["anioFiscal", "userInfo"]),
    fechaMaximaFiltro() {
      // Fecha actual en formato ISO 8601 format
      return moment().format("YYYY-MM-DD");
    },
    dateRangeText() {
      return this.fechas[0] == "" && this.fechas[1] == ""
        ? " "
        : moment(this.fechas[0]).format("D/M/Y") +
            " - " +
            moment(this.fechas[1]).format("D/M/Y");
    },
  },

  methods: {
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;
      this.obtainListProcesos();
    },
    filterName() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        await this.obtainListProcesos();
      }, 500);
    },
    async fetchModalidades() {
      const response = await this.services.Paac.getModalidades({
        compra_tradicional: true,
      });
      if (response) {
        this.ctlModalidades = response.data.forma_contratacion;
      }
    },
    async fetchInstituciones() {
      const response =
        await this.services.Instituciones.getCatalogoInstituciones({
          pagination: false,
        });
      if (response) {
        this.ctlInstituciones = response.data;
      }
    },
    setFiltrosFechas() {
      this.filters.fecha_desde = this.fechas[0];
      this.filters.fecha_hasta = this.fechas[1];
    },
    clearFiltros() {
      this.filters.nombre = null;
      this.filters.id_forma_contratacion = null;
      this.filters.id_interes_presidente = null;
      this.filters.id_seguimiento_proceso = null;
      this.fechas = ["", ""];
      this.filters.fecha_desde = null;
      this.filters.fecha_hasta = null;
      this.filters.id_institucion = null;
      this.obtainListProcesos();
    },
    async obtainListProcesos() {

      const { status, data, headers } =
        await this.services.Paac.getListProcesos({
          ...this.filters,
          page: this.page,
          per_page: this.perPage,
        })
      if (status == 200) {
        this.items = data.procesos;
        this.totalRows = Number(headers.total_rows);
      }

    },
    closeModalAddProceso() {
      this.modalAddProceso = false;
    },
    async deleteProceso() {

      const { status } = await this.services.PacProcesos.deleteProcesoCompra(
        this.proceso_seleccionado
      ).catch(() => {

      });
      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: `Proceso eliminado con éxito`,
          type: "success",
        });
        setTimeout(() => {

        }, 1500);
        this.obtainListProcesos();
      }
      this.deleteDialog = false;
    },
    asignarCdp(idProceso) {
      this.$router.push({ name: "proceso-asignar-cdp", params: { idProceso } });
    },
  },
  created() {
    this.obtainListProcesos();
    this.fetchModalidades();
    this.fetchInstituciones();
  },
};
</script>
<style>
.customProcessColor {
  color: #73d2de !important;
}
</style>
